import { ChangeDetectorRef, Component, OnDestroy, OnInit, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireFunctions } from 'angularfire2/functions';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { User, auth } from 'firebase/app';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService, Organisation } from './auth.service';
import { Event } from './event/event';
import { EventService } from './event.service';

@Component({
  selector: 'app-root-2',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy, OnInit {
  mobileQuery: MediaQueryList;
  title = 'app';
  items: Observable<any[]>;
  private _mobileQueryListener: (any) => void;

  currentUser: Observable<User | null>;
  currentEvent: Observable<Event>;
  events: Observable<Event[]>;
  organisation: Observable<Organisation>;

  routeBasic: Observable<string[]>;
  routeAgenda: Observable<string[]>;
  routeExhibitors: Observable<string[]>;
  routeUsers: Observable<string[]>;

  @ViewChild('snav') sidenav: MatSidenav;

  constructor(
    private router: Router,
    private auth: AuthService,
    private eventService: EventService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    public afFunctions: AngularFireFunctions,
    public dialog: MatDialog
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = (x: any) => {
      changeDetectorRef.detectChanges();
      if (x.matches) {
        this.sidenav.close();
      }
    };
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.items = db.collection('events', ref => ref
      .where('published', '==', true)
      .where('private', '==', false)
    ).valueChanges();

    const f = afFunctions.functions.httpsCallable('listEvents');

    f('cool').then(() => { console.log('success cloud function call'); }, () => { console.log('error'); });

  }

  ngOnInit() {
    this.sidenav.toggle();
    this.organisation = this.auth.currentOrg;
    this.events = this.eventService.events;
    this.currentEvent = this.eventService.currentEvent;
    this.currentUser = this.auth.currentUser;

    this.routeBasic = this.eventToRoute(this.currentEvent, 'basic');
    this.routeAgenda = this.eventToRoute(this.currentEvent, 'agenda');
    this.routeExhibitors = this.eventToRoute(this.currentEvent, 'exhibitors');
    this.routeUsers = this.eventToRoute(this.currentEvent, 'users');
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  logout() {
    console.log('logout');
    this.afAuth.auth.signOut();
    this.router.navigate(['/login']);
  }

  openDialog(): void {
    let dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '300px',
      data: {todo: 1},
      position: { top: '10px', left: '10px' },
      hasBackdrop: true,
      disableClose: false
    });

    dialogRef.afterClosed().subscribe(result => {
      // TODO: set current event
      console.log('The dialog was closed', result);
    });
  }

  private eventToRoute(evt: Observable<Event>, route?: string): Observable<string[]> {
    return evt.pipe(map(e => {
      if (e && route) {
        return ['/event', e.id, route];
      } else if (e) {
        return ['/event', e.id];
      } else {
        return ['/event'];
      }
    }));
  }

}


@Component({
  selector: 'dialog-overview-example-dialog',
  template: `
    <h1 mat-dialog-title>Select event</h1>
    <div mat-dialog-content>
      <mat-nav-list class="event-list">
        <div *ngIf="(events | async)?.length === 0">No events</div>
        <a mat-list-item (click)="onNoClick(['event', event.id])" *ngFor="let event of events | async">{{event.name}}</a>
        <mat-divider></mat-divider>
      </mat-nav-list>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick(['event', 'create'])">Create a new event</button>
    </div>
  `,
  styles: ['.event-list { max-height: 300px; }']
})
export class DialogOverviewExampleDialog implements OnInit {

  events: Observable<Event[]>;

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    private eventService: EventService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log('DialogOverviewExampleDialog#constructor', data);
  }


  ngOnInit() {
    console.log('DialogOverviewExampleDialog#ngOnInit');
    this.events = this.eventService.events;
  }

  onNoClick(route): void {
    this.router.navigate(route);
    this.dialogRef.close({data: route});
  }

}
