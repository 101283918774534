import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Observable, ReplaySubject, of } from 'rxjs';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireFunctions } from 'angularfire2/functions';
import { filter, first, map, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { AuthService, Organisation } from './auth.service';
import { Event, EventId } from './event/event';

enum State {
  Loading,
  Done,
}

interface EventResult {
  state: State;
  result: Event[];
}

@Injectable({
  providedIn: 'root',
})
export class EventService {

  private _events = new ReplaySubject<EventResult>(1);
  public readonly events: Observable<Event[]> = this._events.asObservable().pipe(
    filter(x => x.state === State.Done),
    map(x => x.result)
  );

  private _currentEvent = new ReplaySubject<Event>(1);
  public readonly currentEvent: Observable<Event> = this._currentEvent.asObservable();

  constructor(
    private authService: AuthService,
    private db: AngularFirestore,
    private functions: AngularFireFunctions,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    console.log('EventService#constructor');
    this.authService.currentOrg.pipe(
      switchMap((organisation: Organisation) => organisation ? this.fetchEvents(organisation) : of(null)),
      map((events: Event[] | null) => {
        const result: EventResult = {
          state: events ? State.Done : State.Loading,
          result: events ? events : []
        };
        return result;
      })
    ).subscribe(result => {
      console.log('EventService#constructor result', result);
      this._events.next(result);

      // TODO: how to handle 404 id?
      const params = this.getAllRouteParams(this.activatedRoute);
      if (result.state === State.Done && result.result.length > 0) {
        if (params && params.id) {
          this.setCurrentEvent(result.result.find(x => x.id === params.id));
        } else {
          this.setCurrentEvent(result.result[0]);
        }
      } else {
        this.setCurrentEvent(null);
      }
    });

    // TODO: set default current event? How?
    // TODO: might not always load in time to get the proper navigation end event...
    this.router.events.pipe(
      filter(evt => evt instanceof NavigationEnd),
      distinctUntilChanged(),
      map(evt => {
        const params = this.getAllRouteParams(this.activatedRoute);
        return (params && params.id) ? params.id : null;
      }),
      filter(id => id !== null),
      distinctUntilChanged(),
      switchMap(id => this.events.pipe(
        map(events => events.find(e => e.id === id))
      ))
    ).subscribe((evt) => {
      console.log('found event from url events', evt);
      this.setCurrentEvent(evt);
    });
  }

  setCurrentEvent(evt: Event) {
    console.log('EventService#setCurrentEvent', evt);
    this._currentEvent.next(evt);
  }

  // TODO: better handling of missing organisation
  createEvent(evt: Event): Observable<EventId> {
    return this.authService.currentOrg.pipe(
      switchMap(org => org ? this._callCreateEvent(org.id, evt) : of(null)),
      map(response => response.data.eventId),
      first()
    );
  }

  deleteEvent(evt: Event) {
    return this.authService.currentOrg.pipe(
      switchMap(org => org ? this._callDeleteEvent(org.id, evt.id) : of(null)),
      first()
    );
  }

  updateEvent(evt: Event) {
    return this.authService.currentOrg.pipe(
      switchMap(org => org ? this._callUpdateEvent(org.id, evt) : of (null)),
      first()
    );
  }

  private getAllRouteParams(startRoute: ActivatedRoute): any {
    let params = {};
    let route = startRoute;
    while (route.firstChild) {
      params = {...params, ...route.snapshot.params};
      route = route.firstChild;
    }
    params = {...params, ...route.snapshot.params};
    return params;
  }

  private fetchEvents(organisation: Organisation): Observable<Event[]> {
    return this.db.doc(`orgs/${organisation.id}`)
      .collection<Event>('events', ref => ref.orderBy('createdAt', 'desc'))
      .snapshotChanges().pipe(
        map(documentChangeActions => {
          return documentChangeActions.map(dca => {
            const evt: Event = {
              id: dca.payload.doc.id,
              name: dca.payload.doc.get('name'),
              tagline: dca.payload.doc.get('tagline'),
              description: dca.payload.doc.get('description'),
              hashtag: dca.payload.doc.get('hashtag'),
              startDate: dca.payload.doc.get('startDate'),
              endDate: dca.payload.doc.get('endDate'),
              isPublic: dca.payload.doc.get('isPublic') || false,
              published: dca.payload.doc.get('published') || false,
              attendeeCount: dca.payload.doc.get('attendeeCount') || 0,
              venue: dca.payload.doc.get('venue') || {},
              logo: dca.payload.doc.get('logo') || {}
            };
            return evt;
          });
        })
      );
  }

  private _callCreateEvent(orgId: string, evt: Event) {
    const createEventFunction = this.functions.functions.httpsCallable('createEvent');
    // TODO: send evt directly instead of mapping?
    return createEventFunction({
      orgId: orgId,
      name: evt.name,
      tagline: evt.tagline,
      description: evt.description,
      hashtag: evt.hashtag,
      startDate: evt.startDate,
      endDate: evt.endDate,
      venue: evt.venue,
      logo: evt.logo
    });
  }

  private _callDeleteEvent(orgId: string, eventId: string) {
    return this.functions.functions.httpsCallable('deleteEvent')({orgId, eventId});
  }

  private _callUpdateEvent(orgId: string, evt: Event) {
    const updateEventFunction = this.functions.functions.httpsCallable('updateEvent');
    return updateEventFunction({
      orgId: orgId,
      eventId: evt.id,
      name: evt.name,
      tagline: evt.tagline,
      description: evt.description,
      hashtag: evt.hashtag,
      startDate: evt.startDate,
      endDate: evt.endDate,
      published: evt.published,
      isPublic: evt.isPublic,
      venue: evt.venue,
      logo: evt.logo
    });
  }

}
