import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, distinctUntilChanged } from 'rxjs/operators';

// TODO: how to handle initial page load? this.router.events is added to late
@Component({
  selector: 'breadcrumb',
  template: `
    <div class="breadcrumbs">
      <div *ngFor="let breadcrumb of breadcrumbs$ | async; first as firstItem;">
        <mat-icon class="breadcrumb-icon" *ngIf="!firstItem">chevron_right</mat-icon>
        <button mat-button [routerLink]="[breadcrumb.url]">{{ breadcrumb.label }}</button>
      </div>
    </div>
  `,
  styles: ['.breadcrumbs { display: flex; flex-direction: row; }', '.breadcrumb-icon { vertical-align: middle; }']
})
export class BreadcrumbComponent {

  breadcrumbs$ = this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged(),
      map(event => this.buildBreadcrumb(this.activatedRoute.root))
    );

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  buildBreadcrumb(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Array<BreadCrumb> = []
  ): Array<BreadCrumb> {
    const nextUrl = `${url}${route.snapshot.url.toString()}/`;
    const label = (route.routeConfig && route.routeConfig.data) ? route.routeConfig.data.breadcrumb : 'Home';
    const breadcrumb = {
      label: label,
      url: nextUrl
    };
    let newBreadcrumbs = [ ...breadcrumbs ];
    if (route.routeConfig && route.routeConfig.data && route.routeConfig.data.breadcrumb) {
      newBreadcrumbs = [ ...breadcrumbs, breadcrumb ];
    }
    if (route.firstChild) {
      return this.buildBreadcrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }
}

export interface BreadCrumb {
  label: string;
  url: string;
};
