import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, of } from 'rxjs';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore } from 'angularfire2/firestore';
import { filter, map, switchMap } from 'rxjs/operators';
import { User } from 'firebase/app';

export enum State {
    Loading,
    Done,
}

export interface OrganisationResult {
  state: State;
  result: Organisation[];
}

export interface Organisation {
  id: string;
  name: string;
}

export interface OrganisationUser {
  id: string;
  email: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private _orgs = new ReplaySubject<OrganisationResult>(1);
  orgs = this._orgs.asObservable().pipe(
    filter(o => o.state === State.Done),
    map(o => o.result)
  );

  private _currentOrg = new ReplaySubject<Organisation>(1);
  currentOrg = this._currentOrg.asObservable();

  currentUser: Observable<User | null>

  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFirestore
  ) {
    console.log('AuthService#constructor');
    this.afAuth.user.pipe(
      switchMap(user => user ? this.fetchOrganisations(user.uid) : of(null)),
      map(orgs => {
        const result: OrganisationResult = {
          state: orgs ? State.Done : State.Loading,
          result: orgs ? orgs : []
        };
        return result;
      })
    ).subscribe(result => {
      console.log('AuthService#constructor result', result);
      this._orgs.next(result);
      if (result.result.length > 0) {
        this.setCurrentOrganisation(result.result[0]);
      } else {
        this.setCurrentOrganisation(null);
      }
    });

    this.currentUser = this.afAuth.user;
  }

  private fetchOrganisations(uid: string): Observable<Organisation[]> {
    const userDocument = this.db.doc(`users/${uid}`);
    return userDocument.collection<Organisation>('orgs').snapshotChanges().pipe(
      map(documentChangeActions => {
        return documentChangeActions.map(o => {
          const organisation: Organisation = {
            id: o.payload.doc.id,
            name: o.payload.doc.get('name')
          };
          return organisation;
        });
      })
    );
  }

  // TODO: persist selected organisation, how?
  setCurrentOrganisation(org: Organisation): void {
    console.log('AuthService#setCurrentOrganisation', org);
    this._currentOrg.next(org);
  }

  fetchOrganisationUsers(): Observable<OrganisationUser[]> {
    return this.currentOrg.pipe(
      switchMap(org => this._fetchOrganisationUsers(org))
    );
  }

  private _fetchOrganisationUsers(org: Organisation): Observable<OrganisationUser[]> {
    return this.db.doc(`orgs/${org.id}`).collection<OrganisationUser>('users').snapshotChanges().pipe(
      map(documentChangeActions => {
        return documentChangeActions.map(u => {
          const user: OrganisationUser = {
            id: u.payload.doc.id,
            email: u.payload.doc.get('email')
          };
          return user;
        });
      })
    );
  }

}
