import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFireFunctionsModule } from 'angularfire2/functions';

import { AppRoutingModule } from './app-routing.module';
import { AppMaterialModule } from './app-material.module';
import { BaseComponent } from './base.component';
import { AppComponent, DialogOverviewExampleDialog } from './app.component';
import { BreadcrumbComponent } from './breadcrumb.component';
import { LoginComponent } from './login.component';
import { EventListComponent } from './event-list.component';
import { PageNotFoundComponent } from './not-found.component';
import { NoOrganisationComponent } from './no-organisation.component';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    BaseComponent,
    AppComponent,
    DialogOverviewExampleDialog,
    LoginComponent,
    EventListComponent,
    PageNotFoundComponent,
    BreadcrumbComponent,
    NoOrganisationComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'eventapp-web' }),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule
  ],
  entryComponents: [
    DialogOverviewExampleDialog
  ],
  providers: [],
  bootstrap: [BaseComponent]
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string
  ) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
