import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireFunctions } from 'angularfire2/functions';
import { Observable } from 'rxjs';
import { auth } from 'firebase/app';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
  styleUrls: ['./app.component.css']
})
export class BaseComponent {

}
