import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class OrganisationGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate() {
    console.log('OrganisationGuard#canActivate called');
    return this.authService.orgs.pipe(
      map(orgs => {
        console.log('OrganisationGuard#canActivate observable', orgs);
        if (orgs.length > 0) {
          return true;
        } else {
          this.router.navigate(['/no-organisation']);
          return false;
        }
      })
    );
  }
}
