import { NgModule } from '@angular/core';
import {
  MatExpansionModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatGridListModule,
  MatDatepickerModule,
  MatRadioModule,
  MatSortModule,
  MatTabsModule,
  MatStepperModule,
  MatPaginatorModule,
  MatTableModule,
  MatDividerModule,
  MatDialogModule,
  MatSnackBarModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatCardModule,
  MatInputModule,
  MatFormFieldModule,
  MatMenuModule,
  MatButtonModule,
  MatListModule,
  MatCheckboxModule,
  MatToolbarModule,
  MatIconModule
} from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {MatSidenavModule} from '@angular/material/sidenav';

@NgModule({
  imports: [
    MatExpansionModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatGridListModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatRadioModule,
    MatSortModule,
    MatTabsModule,
    MatStepperModule,
    MatPaginatorModule,
    MatTableModule,
    MatDividerModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatMenuModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSidenavModule
  ],
  exports: [
    MatExpansionModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatGridListModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatRadioModule,
    MatSortModule,
    MatTabsModule,
    MatStepperModule,
    MatPaginatorModule,
    MatTableModule,
    MatDividerModule,
    MatDialogModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatMenuModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSidenavModule
  ],
})
export class AppMaterialModule { }
