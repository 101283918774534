import { NgModule } from '@angular/core';
import { PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth-guard.service';
import { OrganisationGuard } from './organisation-guard.service';
import { AppComponent } from './app.component';
import { LoginComponent } from './login.component';
import { EventListComponent } from './event-list.component';
import { PageNotFoundComponent } from './not-found.component';
import { NoOrganisationComponent } from './no-organisation.component';


const appRoutes: Routes = [
  { path: '', component: AppComponent, canActivate: [AuthGuard, OrganisationGuard], children:
    [
      { path: 'admin', loadChildren: './admin/admin.module#AdminModule', data: { breadcrumb: 'Settings' } },
      { path: 'event', loadChildren: './event/event.module#EventModule', data: { breadcrumb: 'Events' } },
      { path: 'heroes', component: EventListComponent },
      { path: 'settings', component: EventListComponent }
    ]
  },
  { path: 'no-organisation', component: NoOrganisationComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: '/heroes', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false, // TODO: set this only in development builds
        preloadingStrategy: PreloadAllModules
      }
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
  constructor(router: Router) {
    console.log('Routes: ', JSON.stringify(router.config, undefined, 2));
  }
}
