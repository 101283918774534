import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable } from 'rxjs';
import { auth } from 'firebase/app';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  public loading = false;

  loginForm = new FormGroup ({
    email: new FormControl('', [
      Validators.required,
      Validators.email,
    ]),
    password: new FormControl('', [Validators.required])
  });

  constructor(
    private snackBar: MatSnackBar,
    private afAuth: AngularFireAuth,
    private router: Router
  ) {}

  onSubmit() {
    console.log('submit', this.loginForm.value);
    this.loading = true;
    const email = this.loginForm.value.email;
    const password = this.loginForm.value.password;
    this.afAuth.auth.signInWithEmailAndPassword(email, password).then((result) => {
      console.log('success', result);
      this.router.navigate(['/']);
    }, (error) => {
      console.log('error', error);
      this.loading = false;
      if (error.code === 'auth/invalid-email') {
        this.snackBar.open(error.message, '', {duration: 5000});
      } else if (error.code === 'auth/user-disabled') {
        this.snackBar.open('Your user has been disabled', '', {duration: 5000});
      } else if (error.code === 'auth/user-not-found') {
        this.snackBar.open('Email/password combination is invalid', '', {duration: 5000});
      } else if (error.code === 'auth/wrong-password') {
        this.snackBar.open('Email/password combination is invalid', '', {duration: 5000});
      }
    });
  }

}
