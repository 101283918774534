import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';

@Component({
  template: `
    <mat-card>
      <h1>Account does not belong to any organisation</h1>
      <p>Either contact your organisation to be invited or contact our support to setup your account.</p>
      <button mat-raised-button (click)="logout()">Logout</button>
    </mat-card>
  `
})
export class NoOrganisationComponent {

  constructor(
    private router: Router,
    private afAuth: AngularFireAuth
  ) {}

  logout() {
    console.log('logout');
    this.afAuth.auth.signOut();
    this.router.navigate(['/login']);
  }

}
